// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NavigationsBlockFooter_I3Mde{border-left:1px solid #e3e5e9;display:flex;flex:0 0 50%;overflow:hidden;padding:4rem 0 4rem 4rem}@media(max-width:1279.98px){.NavigationsBlockFooter_I3Mde{border-left:none;padding:0 0 4.8rem;width:100%}}@media(max-width:767.98px){.NavigationsBlockFooter_I3Mde{padding:0 0 34px}}.menu_cqUpI{display:grid;font-size:1.8rem;font-weight:500;grid-auto-flow:column;grid-template:repeat(5,1fr)/repeat(2,1fr);height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;letter-spacing:-.072rem;line-height:2.16rem;margin-top:auto;width:100%;grid-gap:1.2rem 2rem}@media(max-width:1279.98px){.menu_cqUpI{grid-gap:0 1.6rem;margin-top:0}}@media(max-width:767.98px){.menu_cqUpI{grid-gap:1.6rem 1.2rem}}.link_4ICJw{cursor:pointer;display:block;transition:opacity .3s ease,color .3s ease;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}@media(-ms-high-contrast:active),(-ms-high-contrast:none),(hover){.link_4ICJw:hover{color:#855aff}}@media(max-width:1279.98px){.item_Xd7fr{padding:1rem 0}}@media(max-width:767.98px){.item_Xd7fr{padding:0}}.item_Xd7fr._active_J1ByF .link_4ICJw{color:#855aff}.item_Xd7fr._disabled_k7tXF .link_4ICJw{opacity:.6;pointer-events:none}.wrapper_ngrzb{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"NavigationsBlockFooter": "NavigationsBlockFooter_I3Mde",
	"menu": "menu_cqUpI",
	"link": "link_4ICJw",
	"item": "item_Xd7fr",
	"_active": "_active_J1ByF",
	"_disabled": "_disabled_k7tXF",
	"wrapper": "wrapper_ngrzb"
};
module.exports = ___CSS_LOADER_EXPORT___;
