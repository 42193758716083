export const navigations = [
    {
        label: 'Школа',
        href: 'https://dvizhenie.school/?utm_source=dvizhenie_ru',
        row: 1,
        column: 1,
    },
    {
        label: 'Медиа',
        to: '/media',
        row: 2,
        column: 1,
    },
    {
        label: 'Работа',
        to: '/job/vacancy',
        row: 3,
        column: 1,
    },
    {
        label: 'Маркетплейс',
        to: '/marketplace',
        row: 4,
        column: 1,
    },
    {
        label: 'Журнал',
        href: 'https://dvizhenie.press/',
        row: 5,
        column: 1,
    },
    {
        label: 'Клуб',
        href: 'https://dvizhenie.club/',
        row: 3,
        column: 2,
    },
    {
        label: 'Топ-100',
        to: '/rating',
        row: 4,
        column: 2,
    },
    {
        label: 'Форум',
        href: 'https://dvizh.ru/',
        row: 5,
        column: 2,
    },
    // {
    //     label: 'События',
    //     scrollTo: {
    //         path: '/',
    //         targetId: 'home-events',
    //     },
    // },
];
